import { app as services } from "../app.module";
import moment from "moment";
import { User } from "../models/user";
import { ILocationService, ILogService, IPromise } from "angular";
import { AUTH_COOKIE_NAME, ADMIN_VIEW_COOKIES, REMEMBER_EMAIL_KEY } from "../configuration";
import { ResultStatusCode } from "../models/enums";
import { UserViewModel, StudentViewModel, UpdatedStudentMedicalDetailsWriteModel, AssociateAdditionalInvitationResponse, AddStudentRelationRequest, AddStudentRelationResponse } from "../models/api";
import { IApiService } from "./apiServiceProvider";
import { Student } from "../models/student";
import { IDeviceService } from "./deviceServiceProvider";
import { BlobService } from "./blobService";
import { CapacitorCookies } from "@capacitor/core";
import { ICommonService } from "./common";
import { ILocalStorageService } from "angular-local-storage";
import angular from "angular";
import { ICookieService } from "./cookieService";
import { PhotoService } from "./photoService";
import { ICsrfTokenService } from "./csrfTokenService";
import { RegistrationSyncService } from "./registrationSyncService";

const serviceId = "dataContext";

services.factory(serviceId, dataContext);

export interface IDataContext {
    events: {
        userSignedIn: string;
        userSignedOut: string;
        dataRefreshed: string;
    };
    preSignIn: () => void;
    flagSignedIn: (email: string) => void;
    flagSignedOutWithoutRaisingEvent: () => IPromise<void>;
    flagSignedOut: (doNotRaiseUserSignedOut: boolean) => IPromise<void>;
    verifyAuthenticated: () => IPromise<void>;
    isAuthenticated: () => boolean;
    onUserSignedIn: (subscriber: any) => () => void;
    onUserSignedOut: (subscriber: any) => () => void;
    onDataRefreshed: (subscriber: any) => () => void;
    getData: () => IPromise<User>;
    saveParentDetails: (p: User) => IPromise<User>;
    refreshData: () => IPromise<User>;
    saveStudentRelation: (relation: AddStudentRelationRequest) => IPromise<AddStudentRelationResponse>;
    saveStudentDetails: (student: Student) => IPromise<any>;
    saveStudentMedicalConditions: (student: UpdatedStudentMedicalDetailsWriteModel) => IPromise<any>;
    emailAcademicReportArchive: (studentEqId: string, academicYear: string) => IPromise<any>;
    associateAdditionalRegistration: (sessionId: string) => IPromise<IAssociateAdditionalRegistration>;
    isAdminView: () => boolean;
    maybeSignInAdmin: () => void;
    clearAuthentication: () => IPromise<void>;
}

function dataContext(
    common: ICommonService,
    apiService: IApiService,
    $q: ng.IQService,
    $rootScope,
    $log: ILogService,
    $location: ILocationService,
    localStorageService: ILocalStorageService,
    deviceService: IDeviceService,
    blobService: BlobService,
    photoService: PhotoService,
    cookieService: ICookieService,
    csrfTokenService: ICsrfTokenService,
    registrationSyncService: RegistrationSyncService
): IDataContext {
    "ngInject";
    let cachedData: IPromise<User> = $q.defer<User>().promise;

    var events = {
        userSignedIn: "auth.userSignedIn",
        userSignedOut: "auth.userSignedOut",
        dataRefreshed: "data.refreshed"
    };

    return {
        events: events,
        preSignIn: preSignIn,
        flagSignedIn: flagSignedIn,
        flagSignedOutWithoutRaisingEvent: flagSignedOutWithoutRaisingEvent,
        flagSignedOut: flagSignedOut,
        verifyAuthenticated: verifyAuthenticated,
        isAuthenticated: isAuthenticated,
        onUserSignedIn: onUserSignedIn,
        onUserSignedOut: onUserSignedOut,
        onDataRefreshed: onDataRefreshed,
        getData: getData,
        saveParentDetails: saveParentDetails,
        refreshData: refreshData,
        saveStudentRelation: saveStudentRelation,
        saveStudentDetails: saveStudentDetails,
        saveStudentMedicalConditions: saveStudentMedicalConditions,
        emailAcademicReportArchive: emailAcademicReportArchive,
        associateAdditionalRegistration: associateAdditionalRegistration,
        isAdminView: isAdminView,
        maybeSignInAdmin: maybeSignInAdmin,
        clearAuthentication: clearAuthentication
    };

    function maybeSignInAdmin() {
        if (deviceService.isMobileApp) return;

        if (getCookie(ADMIN_VIEW_COOKIES.IS_ADMIN_VIEW)) {
            flagSignedIn(null);
        }
    }

    function preSignIn() {
        localStorageService.set(AUTH_COOKIE_NAME, false);
        cachedData = $q.defer<User>().promise;
    }

    function flagSignedIn(email) {
        localStorageService.set(AUTH_COOKIE_NAME, true);
        if (deviceService.isMobileApp) {
            localStorageService.set(REMEMBER_EMAIL_KEY, email);
        } else if (getCookie(ADMIN_VIEW_COOKIES.IS_ADMIN_VIEW)) {
            Object.keys(ADMIN_VIEW_COOKIES)
                .map(k => ADMIN_VIEW_COOKIES[k])
                .forEach(key => {
                    var cookieValue = getCookie(key);
                    if (cookieValue) {
                        sessionStorage.setItem(key, cookieValue);
                        deleteCookie(key);
                    }
                });
        }
    }

    function getCookie(key: string) {
        var keyAndEquals = key + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(keyAndEquals) == 0) return c.substring(keyAndEquals.length, c.length);
        }
        return null;
    }

    function deleteCookie(key: string) {
        cookieService.deleteCookie(key);
    }

    function flagSignedOutWithoutRaisingEvent() {
        return flagSignedOut(true);
    }

    function flagSignedOut(doNotRaiseUserSignedOut: boolean) {
        cachedData = $q.defer<User>().promise;
        localStorageService.remove(REMEMBER_EMAIL_KEY);

        if (!doNotRaiseUserSignedOut) {
            raiseUserSignedOut();
        }

        return clearAuthentication();
    }

    function clearAuthentication() {
        var deferred = $q.defer<void>();

        localStorageService.set(AUTH_COOKIE_NAME, false);

        if (!deviceService.isMobileApp) {
            $log.debug("dataContext - Clear Authentication Cookies");
            Object.keys(ADMIN_VIEW_COOKIES)
                .map(k => ADMIN_VIEW_COOKIES[k])
                .forEach(key => {
                    sessionStorage.removeItem(key);
                    deleteCookie(key);
                });
            deferred.resolve();
        } else {
            $log.debug("dataContext - Clear Mobile Authentication Cookies");
            // Clear All Cookies
            CapacitorCookies.getCookies().then(cookies => {
                const cookieDeletePromises = Object.keys(cookies)
                    // keep trusted device cookie
                    .filter(k => k !== "PingIdTrustedDevice")
                    .map(key =>  
                        CapacitorCookies.deleteCookie({key})
                    );
                
                $q.all(cookieDeletePromises)
                    .catch(response => {
                        $log.error("dataContext - Unable to clear cookies", angular.toJson(response));
                    })
                    .finally(() => {
                        deferred.resolve();
                    });
            })
        }

        return deferred.promise;
    }

    function verifyAuthenticated() {
        var deferred = $q.defer<void>();
        registrationSyncService.waitOnRegistrationToComplete()
        .then(() =>
            fetchData()
                .then(function (user) {
                    if (user.hasPendingTermsAndConditions()) {
                        $location.path("/termsAndConditions");
                        deferred.reject();
                        return;
                    }

                    raiseUserSignedIn(user);
                    deferred.resolve();
                })
                .catch(response => {
                    $log.error("dataContext - Unable to verify authentication", angular.toJson(response));
                    deferred.reject();
                }));

        return deferred.promise;
    }

    function getData() {
        return cachedData;
    }

    function refreshData() {
        return fetchData();
    }

    function isAuthenticated() {
        return !!localStorageService.get(AUTH_COOKIE_NAME);
    }

    function isAdminView() {
        return !deviceService.isMobileApp && !!sessionStorage.getItem(ADMIN_VIEW_COOKIES.IS_ADMIN_VIEW);
    }

    function raiseUserSignedIn(data: User) {
        common.broadcast(events.userSignedIn, data);
    }

    function onUserSignedIn(subscriber) {
        return $rootScope.$on(events.userSignedIn, subscriber);
    }

    function raiseUserSignedOut() {
        common.broadcast(events.userSignedOut);
    }

    function onUserSignedOut(subscriber) {
        return $rootScope.$on(events.userSignedOut, subscriber);
    }

    function onDataRefreshed(subscriber) {
        return $rootScope.$on(events.dataRefreshed, subscriber);
    }

    async function refreshTokenAndFetchUser() {
        await csrfTokenService.ensureCsrfToken();
        return apiService.getUser<UserViewModel>();
    }

    function fetchData(): IPromise<User> {
        $log.debug("Fetching user data");
        $rootScope.showFullScreenLoadingPane();
        const user = isAdminView()
            ? getAdminViewUser()
            : refreshTokenAndFetchUser();

        cachedData = user
            .then(response => {
                const data = response.data;
                if (response.status == 200) {
                    var user = new User(data, deviceService.isMobileApp);
                    loadStudentImages(user).then(() => {
                        common.broadcast(events.dataRefreshed, user);
                    });
                    return user;
                }
                throw new Error("User is not logged in.");
            })
            .catch(error => {
                throw error;
            })
            .finally(() => $rootScope.hideFullScreenLoadingPane());
        return cachedData;
    }

    function getAdminViewUser() {
        var schoolUserId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.SCHOOL_USER_ID);
        var ppaoId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.PARENT_ID);
        var centreCode = sessionStorage.getItem(ADMIN_VIEW_COOKIES.CENTRE_CODE);

        return apiService.getAdminViewUser<UserViewModel>(schoolUserId, ppaoId, centreCode);
    }

    function saveParentDetails(p: User) {
        return apiService.saveUser(p).then(
            res => res.data,
            res => $q.reject(res.data)
        );
    }

    function saveStudentRelation(relation) {
        return apiService.saveStudentRelation<StudentViewModel>(relation).then(r => r.data);
    }

    function saveStudentDetails(student: Student) {
        var postData = {
            oneSchoolId: student.oneSchoolId,
            postalAddress: student.postalAddress,
            residentialAddress: student.residentialAddress,
            dateOfBirth: getUtcDate(student.dateOfBirth)
        };

        return apiService.saveStudent(postData).then(r => r.data);
    }

    function getUtcDate(date: Date | string) {
        if (date == undefined) {
            return undefined;
        }
        const dateObject = moment(date).toDate();
        const utcDate = moment.utc(Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate()));
        return utcDate;
    }

    function saveStudentMedicalConditions(student: UpdatedStudentMedicalDetailsWriteModel) {
        return apiService.saveStudentMedicalConditions(student).then(r => r.data);
    }

    function emailAcademicReportArchive(studentEqId, academicYear) {
        return apiService
            .emailAcademicReportArchive({
                eqId: studentEqId,
                academicYear: academicYear
            })
            .then(r => r.data);
    }

    async function associateAdditionalRegistration(sessionId: string): Promise<IAssociateAdditionalRegistration> {
        try {
            var result = await apiService.associateAdditionalInvitation<AssociateAdditionalInvitationResponse>({
                sessionId: sessionId
            });

            return {
                valid: result.data.resultCode === ResultStatusCode.Valid,
                invalid: result.data.resultCode === ResultStatusCode.Invalid,
                codeAlreadyUsed: result.data.resultCode === ResultStatusCode.CodeAlreadyUsed,
                userNotAccountOwner: result.data.resultCode === ResultStatusCode.UserNotAccountOwner,
                errorMessage: result.data.errorMessage
            };
        } catch {
            return {
                valid: false,
                invalid: true,
                codeAlreadyUsed: false,
                userNotAccountOwner: false
            };
        }
    }

    function loadStudentImages(user: User) {
        const promises = [];
        user.students.forEach(function (s: Student) {
            if (s.photoContentUrl) {
                return;
            }

            promises.push(loadStudentImage(s));
        });
        return $q.all(promises);
    }

    function loadStudentImage(s: Student) {
        const deferred = $q.defer();

        if (s.hasPhoto()) {
            photoService
                .downloadPhoto(s.photoUrlOrPlaceholder())
                .then(result => {
                    s.photoContentUrl = result;
                })
                .finally(() => {
                    deferred.resolve();
                });
        } else {
            blobService.createBlobFromDataUrl(s.photoUrlOrPlaceholder(), "image/png").then(blob => {
                blobService
                    .getPhotoContentUrl(blob)
                    .then(url => {
                        s.photoContentUrl = url;
                    })
                    .finally(() => {
                        deferred.resolve();
                    });
            });
        }
        return deferred.promise;
    }
}

export interface IAssociateAdditionalRegistration {
    valid: boolean;
    invalid: boolean;
    codeAlreadyUsed: boolean;
    userNotAccountOwner: boolean;
    errorMessage?: string;
}
