import { IApiService } from "@/app/services/apiServiceProvider";
import { app } from "../../app.module";
import { SESSION_AO_CODE_KEY } from "../../configuration";
import { ICsrfTokenService } from "@/app/services/csrfTokenService";
import { RegistrationSyncService } from "@/app/services/registrationSyncService";

const serviceId = "accountOwnerRegisterInitialise";

app.factory(serviceId, accountOwnerRegisterInitialise);

function accountOwnerRegisterInitialise(
    $q,
    $route,
    $location,
    $rootScope,
    apiService: IApiService,
    localStorageService,
    csrfTokenService: ICsrfTokenService,
    registrationSyncService: RegistrationSyncService
) {
    "ngInject";
    return function () {
        if(registrationSyncService.hasPendingRegistration()) {
            return registrationSyncService.waitOnRegistrationToComplete();
        }
        var deferred = $q.defer();
        registrationSyncService.pendingRegistration(deferred.promise)
        var dvsUserId = $route.current.params.userId;
        var sessionId = localStorageService.get(SESSION_AO_CODE_KEY);
        if (!dvsUserId || !sessionId) {
            $location.path("/");
            deferred.reject();
        } else {
            $rootScope.showFullScreenLoadingPane();

            csrfTokenService
                .ensureCsrfToken()
                .then(() => apiService
                    .accountOwnerActivate({ sessionId: sessionId, userId: dvsUserId }))
                .then(response => {
                    deferred.resolve(response.data);
                })
                .catch(() => {
                    deferred.reject();
                })
                .finally(() => {
                    $rootScope.hideFullScreenLoadingPane();
                });
        }

        return deferred.promise;
    };
}
