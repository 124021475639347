import { app as services } from "../app.module";

var serviceId = "registrationSyncService";

export class RegistrationSyncService {

    private registrationPendingPromise: Promise<void>;

    public waitOnRegistrationToComplete(): Promise<void> {
        return this.registrationPendingPromise || Promise.resolve();
    }
    
    public hasPendingRegistration() {
        return !!this.registrationPendingPromise;
    }

    public pendingRegistration(pending: Promise<void>): void {
        this.registrationPendingPromise = pending;
    }
}

services.service(serviceId, RegistrationSyncService);
